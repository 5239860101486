<template>
  <div class="pa-2">
    <v-sheet rounded elevation="2" class="mb-5 pa-2 brand-header">
      <div class="flex align__center">
        <div>
          <h2>Appflows</h2>
          <p class="mb-0">{{ currentMenuName }}</p>
        </div>
      </div>
    </v-sheet>
    <div class="flex justify__between mb__default">
      <v-btn fab x-small color="primary">
        <v-icon @click="goBack()">mdi-arrow-left</v-icon>
      </v-btn>
      <v-btn fab x-small color="primary" @click="refreshContent()">
        <v-icon :disabled="isLoading"> mdi-refresh </v-icon>
      </v-btn>
    </div>
    <v-list v-if="!isLoading" class="flows-container">
      <v-list-item
        v-for="flow in associatedFlows"
        :key="flow.botflow_config.id"
        class="menu-item"
        @click="handleFlowSelection(flow)"
      >
        <v-list-item-icon>
          <v-icon :style="getIconColor(flow.botflow_config.config.main_config)">
            {{ getIcon(flow.botflow_config.config.main_config) }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            <p class="flow-name">{{ flow.botflow_config.name }}</p>
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ flow.botflow_config.description }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-icon>
          <v-icon class="menu-item__icon">mdi-chevron-right</v-icon>
        </v-list-item-icon>
      </v-list-item>
    </v-list>
    <v-skeleton-loader v-if="isLoading" type="list-item-avatar-two-line@6" />
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { camelToKebabCase } from "@/utils/conversion";
export default {
  name: "Menu",
  data: () => ({
    isLoading: false,
    currentMenuName: "",
    associatedFlows: [],
  }),
  created() {
    if (!this.$route.params.name) {
      this.$router.replace("/flows");
      return;
    }
    this.currentMenuName = this.$route.params.name;
  },
  computed: {
    ...mapState({
      flows: (state) => state.company.botFlows,
      settings: (state) => state.company.settings,
      currentBotUser: (state) => state.users.currentUser,
      flowsPerConnector: (state) => state.company.flowsPerConnector,
      selectedConnector: (state) => state.connectors.selectedConnector,
    }),
    getAvaiableFlows() {
      const groups = this.currentBotUser.groups.join(",");
      return this.flows
        .filter((flow) => {
          return (
            flow.config.main_config.menu_name === this.currentMenuName &&
            flow.enabled &&
            !flow.config.main_config?.no_standalone_execution_ga &&
            !flow.config.main_config?.no_standalone_execution_lb
          );
        })
        .filter((flow) =>
          flow.allowed_roles.some((group) => groups.includes(group))
        );
    },
  },
  watch: {
    flowsPerConnector(newVal, oldVal) {
      if (newVal === oldVal) return;
      this.filterAvailableFlows();
    },
  },
  mounted() {
    this.filterAvailableFlows();
  },
  methods: {
    ...mapMutations({
      setCurrentFlow: "company/setCurrentFlow",
    }),
    getIcon(mainConfig) {
      if (!mainConfig.icon) return "mdi-robot-excited";
      return camelToKebabCase(mainConfig?.icon?.icon || mainConfig.icon);
    },
    getIconColor(mainConfig) {
      if (mainConfig.icon?.color) {
        const { r, g, b, a } = mainConfig.icon?.color;
        return `color: rgba(${r}, ${g}, ${b}, ${a});`;
      }
      return "color: var(--sidebar-link-color);";
    },
    goBack() {
      this.$router.push("/app-menus");
    },
    async refreshContent() {
      this.isLoading = true;
      try {
        await this.$store.dispatch(
          "company/fetchBotFlows",
          this.settings.company_id
        );
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    filterAvailableFlows() {
      if (!this.flowsPerConnector.length) return;
      let groups = this.currentBotUser?.groups.join(",");
      if (this.$store.state.company.settings.id === "Hight.io-Demo") {
        groups = ["Demo group"];
      }
      this.associatedFlows = this.flowsPerConnector
        .filter((flow) => {
          const mainConfig = flow.botflow_config.config.main_config;
          return (
            flow.status === "PUBLISHED" &&
            !mainConfig?.no_standalone_execution_ga &&
            !mainConfig?.no_standalone_execution_lb &&
            flow.menu === this.currentMenuName
          );
        })
        .filter((flow) =>
          flow.botflow_config.allowed_roles.some((group) =>
            groups?.includes(group)
          )
        );
    },
    handleFlowSelection(flow) {
      this.setCurrentFlow(flow.botflow_config);
      this.$router.push("/flow");
    },
  },
};
</script>

<style lang="scss" scoped>
.flow-name {
  max-width: 240px;
  word-break: normal;
  white-space: normal;
  margin-bottom: 0;
}
.v-list .v-list-item.menu-item {
  color: var(--sidebar-link-color) !important;
  .menu-item {
    &__icon {
      color: inherit;
    }
  }
}
</style>
